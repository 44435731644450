<template>
  <div class="component-tablebox">
    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline @submit.prevent>
          <b-input-group class="w-35 mb-2">
            <b-form-input v-model="searchTerm" @keydown.native="search" placeholder="Search..."></b-form-input>
            <template #append v-if="searchItems">
              <b-dropdown :text="selectedSearchVariable" variant="success">
                <b-dropdown-item v-for="item in searchItems" :key="item" @click="selectedSearchVariable = item">{{
                  item
                }}</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>
        </b-form>
      </b-col>
      <!-- <b-col sm class="px-0">
        <b-form inline class="float-right">
          <b-form-datepicker
            id="start-datepicker"
            placeholder="Start Date"
            :min="minDate"
            :max="maxDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="startDateValue"
            class="ml-2 mb-2"
          ></b-form-datepicker>
          <b-form-datepicker
            id="end-datepicker"
            placeholder="End Date"
            :min="minDate"
            :max="maxDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="endDateValue"
            class="ml-2 mb-2"
          ></b-form-datepicker>
          <b-button variant="success" class="ml-2 mb-2" @click="applyDate">Apply</b-button>
          <b-button variant="outline-secondary" class="ml-2 mb-2" @click="clearDate">Clear</b-button>
        </b-form>
      </b-col> -->
    </b-row>
    <b-row class="table-bottom-wrapper mb-2 mx-0">
      <b-col :cols="12" class="px-0">
        <b-button v-if="showAdd" size="sm" variant="success" class="mr-2" @click="clickAdd">
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button v-if="showUpload" size="sm" variant="success" class="mr-2" @click="clickUpload">
          <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ uploadText }}
        </b-button>
        <b-button v-if="showDownload" size="sm" variant="success" class="mr-2" @click="clickDownload">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
        <b-button v-if="showDownloadCallerList" size="sm" variant="success" class="mr-2" @click="clickDownloadCaller">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ callerDownloadText }}
        </b-button>
        <b-button
          v-if="showDownloadMessageReport"
          size="sm"
          variant="success"
          class="mr-2"
          @click="clickDownloadReport"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadMessageReportText }}
        </b-button>
      </b-col>
    </b-row>
    <div class="table-top-wrapper">
      <div v-if="totalCount" class="row-total-count">
        <span>Total Count:</span>
        {{ totalCount }}
      </div>
    </div>
    <div class="table-container">
      <div class="table-row table-row-header">
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          class="table-column"
          :class="{
            ...column.class,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>
      <div class="table-row" v-for="row in rows" :key="row.id">
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          class="table-column"
          :class="{
            ...column.class,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <span v-if="column.textKey" class="span-text">{{ row[column.textKey] }}</span>
          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group size="sm">
              <b-button
                class="mb-1"
                v-if="column.functions.ack"
                size="sm"
                variant="success"
                @click="clickAcknowledge(row)"
              >
                <font-awesome-icon :icon="['fas', 'sms']" class="mr-1" />Acknowledge
              </b-button>
            </b-button-group>
            <b-button-group size="sm">
              <b-button v-if="column.functions.addOrder" size="sm" variant="primary" @click="clickAddOrder(row)">
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add Order
              </b-button>
              <b-button v-if="column.functions.edit" size="sm" variant="secondary" @click="clickEdit(row)">
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />View/Edit
              </b-button>
              <b-button v-if="column.functions.delete" size="sm" variant="warning" @click="clickDelete(row)">
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />Delete
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div v-if="!loading && rows.length === 0" class="table-row table-row-empty">
        <div class="table-column table-column-full text-center">{{ emptyText }}</div>
      </div>
      <div v-if="loading" class="table-row table-row-empty">
        <div class="table-column table-column-full text-center">
          <span class="spinner spinner-black"></span>
        </div>
      </div>
    </div>
    <b-row class="table-bottom-wrapper mt-2 mx-0">
      <b-col :cols="8" class="px-0">
        <b-button v-if="showAdd" size="sm" variant="success" class="mr-2" @click="clickAdd">
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button v-if="showDownload" size="sm" variant="success" class="mr-2" @click="clickDownload">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
        <b-button v-if="showDownloadCallerList" size="sm" variant="success" class="mr-2" @click="clickDownload">
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ callerDownloadText }}
        </b-button>
        <b-button v-if="showUpload" size="sm" variant="success" class="mr-2" @click="clickUpload">
          <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ uploadText }}
        </b-button>
        <b-button
          v-if="showDownloadMessageReport"
          size="sm"
          variant="success"
          class="mr-2"
          @click="clickDownloadReport"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadMessageReportText }}
        </b-button>
      </b-col>
      <b-col :cols="4" class="px-0 text-right" v-if="showPagination">
        <b-pagination-nav
          v-if="rows.length"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
        ></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    rows: Array,
    pagination: Object,
    baseUrl: String,
    emptyText: String,
    showAdd: Boolean,
    showDownload: Boolean,
    showDownloadCallerList: Boolean,
    showToolbar: Boolean,
    searchItems: Array,
    showDownloadMessageReport: Boolean,
    showUpload: Boolean,
    downloadText: String,
    callerDownloadText: String,
    uploadText: String,
    downloadMessageReportText: String,
    addText: String,
    loading: Boolean,
    showPagination: Boolean
  },
  name: 'TableBox',
  data() {
    return {
      filterSelected: null,
      searchTerm: null,
      selectedSearchVariable: this.searchItems && this.searchItems[0]
    };
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
  },
  watch: {
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  computed: {
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  methods: {
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    columnStyle(column) {
      return { width: column.width };
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    clickAddOrder(row) {
      this.$emit('add-order', { row });
    },
    clickAdd() {
      this.$emit('add', {});
    },
    clickDownload() {
      this.$emit('download', {});
    },
    clickDownloadCaller() {
      this.$emit('download-inbound', {});
    },
    clickDownloadReport() {
      this.$emit('download-message-report', {});
    },
    clickUpload() {
      this.$emit('show-upload', {});
    },
    clickEdit(row) {
      this.$emit('edit', { row });
    },
    clickDelete(row) {
      this.$emit('delete', { row });
    },
    clickAcknowledge(row) {
      this.$emit('acknowledge', { row });
    }
  }
};
</script>

<style scoped>
.component-tablebox {
  font-size: 0.9rem;
}

.table-top-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
}

.table-container {
  display: block;
  width: 100%;
}

.row-total-count {
  margin-bottom: 0.5rem;
}

.row-total-count span {
  font-weight: bold;
}

.table-row {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px #d9d9d9;
  transition: 0.5s;
  margin-left: 0;
  margin-right: 0;
}

.table-row:first-of-type {
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
  border-bottom: solid 2px #d9d9d9;
}

.table-column {
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  word-wrap: break-word;
}

.table-columns {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
}

.table-columns .table-column {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}

.table-columns .table-column:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-row:first-of-type .table-column {
  background: #fff;
  color: #000;
  border-color: #d9d9d9;
  font-weight: bold;
}

.table-row:nth-child(odd) .table-column {
  background: #f4f2f1;
}

.table-row:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-column-full {
  width: 100%;
}

.table-column-rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flex-cell {
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

@media all and (max-width: 767px) {
  .table-row {
    border-bottom: solid 2px #d9d9d9;
    border-right: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 50% !important;
    border: 0;
    text-align: left !important;
    background: #fff;
  }

  .table-row .table-column.table-column-first {
    border-bottom: solid 1px #d9d9d9;
    width: 100% !important;
  }

  .table-row .table-column.table-column-last-odd {
    width: 100% !important;
  }
}

@media all and (max-width: 430px) {
  .table-column {
    width: 100%;
  }

  .column .table-column {
    border-bottom: solid 1px;
  }

  .table-column.table-column-first {
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 100% !important;
    border-bottom: 0;
    text-align: left !important;
  }

  .table-row .table-column:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .table-columns {
    width: 100%;
  }

  .table-columns.table-column {
    border-bottom: solid 1px #d9d9d9;
  }

  .flex-cell {
    width: 100%;
  }
}
</style>
